import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { graphql, useStaticQuery, Link } from "gatsby";
import Select from "react-select";
import * as Yup from "yup";
import axios from "axios";
import PolicyInactive from "../images/svg/policy-inactive.svg";
import PolicyActive from "../images/svg/policy-active.svg";

/**
 * Form initialValues
 */
const initialValues = {
  title: "",
  givenName: "",
  familyName: "",
  email: "",
  tel: "",
  company: "",
  subject: "",
  message: "",
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [isPolicyAccept, setIsPolicyAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mailSent, setMailSent] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const selectOptions = [
    { value: "frau", label: t("ms") },
    { value: "herr", label: t("mr") },
    { value: "divers", label: t("divers") },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string(),
    givenName: Yup.string()
      .min(2, `${t("tooShort")}!`)
      .max(50, `${t("tooLong")}!`)
      .required(`${t("firstName")} ${t("required")}`),
    familyName: Yup.string()
      .min(2, `${t("tooShort")}!`)
      .max(50, `${t("tooLong")}!`)
      .required(`${t("familyName")} ${t("required")}`),
    email: Yup.string()
      .email(t("validEmail"))
      .required(`${t("email")} ${t("required")}`),
    tel: Yup.string(),
    company: Yup.string(),
    subject: Yup.string().required(`${t("subject")} ${t("required")}`),
    message: Yup.string()
      .min(5, `${t("tooShort")}!`)
      .required(`${t("message")} ${t("required")}`),
  });

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { siteUrl } = data.site.siteMetadata;

  const customStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#69FF75" : "#fff",
      ":active": {
        backgroundColor: state.isSelected ? "#E1E1FF" : "#E1E1FF",
      },
      ":hover": {
        backgroundColor: state.isSelected ? "#69FF75" : "#E1E1FF",
      },
    }),
    control: (base) => ({
      ...base,
      height: 30,
      minHeight: 30,
    }),
  };

  return (
    <div className="w-full lg:w-7/12 bg-magnolia pt-5 pb-12 pl-7 pr-4">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setIsLoading(true);
          axios({
            method: "post",
            url: `${siteUrl}/api/contact/index.php`,
            headers: { "content-type": "application/json" },
            data: values,
          })
            .then((result) => {
              setIsLoading(false);
              if (result.data.sent) {
                setMailSent(result.data.sent);
                setTimeout(() => {
                  setMailSent(false);
                }, 5000);
                setError(null);
                setIsPolicyAccept(false);
                resetForm(initialValues);
              } else {
                setError(true);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error.message);
              setTimeout(() => {
                setError(null);
              }, 5000);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} action="#">
            <h2 className="text-h2mobile sm:text-h2desktop text-safiaBlue">
              {t("contactForm")}
            </h2>
            <p className="mb-6 mt-4">{t("fieldsAreRequired")}</p>
            <div className="w-full lg:w-45">
              <label className="text-body text-safiaBlue">
                {t("title")}
                <Select
                  styles={customStyles}
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={selectOptions}
                  placeholder={t("pleaseChoose")}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isSearchable={false}
                />
                {/* <input
                  className="block safiainput w-full"
                  type="text"
                  value={values.title}
                  name="title"
                  onChange={handleChange}
                /> */}
                <span className="text-error text-xs">
                  {errors.title && touched.title && errors.title}
                </span>
              </label>
            </div>
            <div className="w-full flex flex-row flex-wrap justify-between mt-5">
              <div className="w-full md:w-45">
                <label className="text-body text-safiaBlue">
                  {`${t("firstName")} *`}
                  <input
                    className="block safiainput w-full"
                    type="text"
                    name="givenName"
                    value={values.givenName}
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.givenName && touched.givenName && errors.givenName}
                  </span>
                </label>
              </div>
              <div className="w-full mt-5 md:mt-0 md:w-6/12">
                <label className="text-body text-safiaBlue">
                  {`${t("familyName")} *`}
                  <input
                    className="block safiainput w-full"
                    type="text"
                    name="familyName"
                    value={values.familyName}
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.familyName &&
                      touched.familyName &&
                      errors.familyName}
                  </span>
                </label>
              </div>
            </div>
            <div className="w-full flex flex-row flex-wrap justify-between mt-5">
              <div className="w-full md:w-45">
                <label className="text-body text-safiaBlue">
                  {`${t("email")} *`}
                  <input
                    className="block safiainput w-full"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.email && touched.email && errors.email}
                  </span>
                </label>
              </div>
              <div className="w-full md:w-6/12 mt-5 md:mt-0">
                <label className="text-body text-safiaBlue">
                  {t("phoneNumber")}
                  <input
                    className="block safiainput w-full"
                    type="text"
                    name="tel"
                    value={values.tel}
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.tel && touched.tel && errors.tel}
                  </span>
                </label>
              </div>
            </div>
            <div className="w-full flex mt-5">
              <div className="w-full">
                <label className="text-body text-safiaBlue">
                  {t("company")}
                  <input
                    className="block safiainput w-full"
                    type="text"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.company && touched.company && errors.company}
                  </span>
                </label>
              </div>
            </div>
            <div className="w-full lg:w-45 flex mt-5">
              <div className="w-full">
                <label className="text-body text-safiaBlue">
                  {`${t("subject")} *`}
                  <input
                    className="block safiainput w-full"
                    type="text"
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.subject && touched.subject && errors.subject}
                  </span>
                </label>
              </div>
            </div>
            <div className="w-full flex mt-5">
              <div className="w-full">
                <label className="text-body text-safiaBlue">
                  {`${t("yourMessage")} *`}
                  <textarea
                    className="block safiatextarea w-full"
                    value={values.message}
                    name="message"
                    onChange={handleChange}
                  />
                  <span className="text-error text-xs">
                    {errors.message && touched.message && errors.message}
                  </span>
                </label>
              </div>
            </div>
            <div
              onClick={() => setIsPolicyAccept(!isPolicyAccept)}
              className="mt-7 mb-12 flex flex-row items-center cursor-pointer"
            >
              <div className="mr-4">
                {isPolicyAccept ? <PolicyActive /> : <PolicyInactive />}
              </div>
              <div className="text-body overflow-hidden">
                <span className="text-safiaBlue">{`${t(
                  "acceptDataProtection"
                )} `}</span>
                <Link
                  className="text-safiaBlue"
                  to="/impressumdatenschutz/"
                >{`${t("dataprotection")}.`}</Link>
              </div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting || !isPolicyAccept}
              style={{ fontFamily: "Bold" }}
              className={`flex ${
                isPolicyAccept ? "bg-screamingGreen" : "bg-lavender"
              } h-10 w-36 items-center justify-center cursor-pointer rounded-full text-body ${
                isPolicyAccept ? "text-safiaBlue" : "text-revolver "
              }`}
            >
              {isLoading ? t("isLoading") : t("submit")}
            </button>
            <div
              className={`bg-screamingGreen text-center px-8 py-4 text-safiaBlue mt-10 contactformmessage ${
                mailSent ? "isVisible" : "hidden"
              }`}
            >
              <span>{t("thanksContacting")}</span>
            </div>
            <div
              className={`bg-error text-center px-8 py-4 text-safiaBlue mt-10 contactformmessage ${
                error ? "isVisible" : "hidden"
              }`}
            >
              <span>{t("errorContact")}</span>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
