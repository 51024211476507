/* eslint-disable import/first */
import React, { useEffect, useState, useRef } from "react";
import Particles from "react-tsparticles";
import { useTranslation } from "react-i18next";

const throttle = require("lodash/throttle");

import ParticleConfig from "../particles/particleconfigSubpage";
import ParticleConfigMobile from "../particles/particleconfigSubpageMobile";
import NewsletterSubscribe from "../components/NewsletterSubscribe";
import NonBreakingSpaceText from "../components/NonBreakingSpaceText";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ContactForm";
import SocialMediaIcons from "../components/SocialMediaIcons";
import PeterPortrait from "../images/team/peter.png";
import TimmPortrait from "../images/team/timm.png";
import useDeviceDetect from "../components/useDeviceDetect";

const ContactPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const particleDiv = useRef(null);
  const [particleHeight, setParticleHeight] = useState(973 + 72);

  useEffect(() => {
    const resize = throttle(() => {
      setParticleHeight(particleDiv.current.clientHeight + 72);
    }, 200);

    setParticleHeight(particleDiv.current.clientHeight + 72);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [particleDiv]);

  return (
    <Layout>
      <SEO
        title={t("contact")}
        meta={[
          {
            name: "cache-control",
            content: "public, max-age=0, must-revalidate",
          },
        ]}
      />
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
        }}
      >
        <Particles
          height={particleHeight}
          className="bg-safiaBlue"
          params={isMobile ? ParticleConfigMobile : ParticleConfig}
        />
        <div ref={particleDiv} className="absolute top-0 w-full">
          <div className="container mx-auto px-8">
            <div style={{ paddingTop: "152px", paddingBottom: "80px" }}>
              <h1 className="w-full lg:w-3/4 text-h1mobile sm:text-h1desktop">
                {t("lookingForwardHearing")}
              </h1>
              <h2
                className="text-body sm:text-h2desktop w-full sm:w-4/5"
                style={{
                  marginTop: "30px",
                  fontFamily: "Regular",
                }}
              >
                <NonBreakingSpaceText text={t("lookingForwardHearingDesc")} />
              </h2>
            </div>
            <div className="flex flex-row flex-wrap justify-between mb-20">
              <ContactForm />
              <div className="w-full lg:w-4/12 mt-20 lg:mt-0">
                <h2
                  style={{ fontFamily: "Bold" }}
                  className="text-h2mobile sm:text-h2desktop"
                >
                  {t("contacts")}
                </h2>
                <div className="mt-12">
                  <div className="flex">
                    <img
                      className="h-20 w-20 rounded-full mb-4"
                      src={PeterPortrait}
                      alt="Kontakt Profilbild"
                    />
                  </div>
                  <div className="text-left">
                    <h4
                      style={{ fontFamily: "Bold" }}
                      className="text-h4desktop mb-0 text-white"
                    >
                      Dr. Peter Carl
                    </h4>
                    <p className="text-h4desktop mb-0 text-white">{t("ceo")}</p>
                  </div>
                  <div className="text-left mt-2">
                    {/* <div className={`text-white mb-0 text-body`}>
                      T:{" "}
                      <a className="no-underline" href={`tel:+491724260922`}>
                        <span>+ 49 (0) 172 4260 922</span>
                      </a>
                    </div> */}
                    <div className={`text-white mb-0 text-body`}>
                      M:{" "}
                      <a href={`mailto:carl@safia.tech`}>
                        <span>carl@safia.tech</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-15">
                  <div className="flex">
                    <img
                      className="h-20 w-20 rounded-full mb-4"
                      src={TimmPortrait}
                      alt="Kontakt Profilbild"
                    />
                  </div>
                  <div className="text-left">
                    <h4
                      style={{ fontFamily: "Bold" }}
                      className="text-h4desktop mb-0 text-white"
                    >
                      Dr. Timm Schwaar
                    </h4>
                    <p className="text-h4desktop mb-0 text-white">{t("ceo")}</p>
                  </div>
                  <div className="text-left mt-2">
                    {/* <div className={`text-white mb-0 text-body`}>
                      T:{" "}
                      <a className="no-underline" href={`tel:+491627323405`}>
                        <span>+ 49 (0) 162 7323 405</span>
                      </a>
                    </div> */}
                    <div className={`text-white mb-0 text-body`}>
                      M:{" "}
                      <a href={`mailto:schwaar@safia.tech`}>
                        <span>schwaar@safia.tech</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-20">
              <h2 className="text-white text-h2mobile sm:text-h2desktop mb-8">
                {t("youCanFindUs")}
              </h2>
              <div className="text-white text-body">
                <span className="text-white font-safiaBold text-body">
                  SAFIA Technologies GmbH
                </span>
                <span className="mt-1 block">
                  {`${t("bam")}`}
                  <br />
                  Richard-Willstätter-Straße 11
                  <br />
                  12489 Berlin, Germany
                  <br />
                  <br />
                  <div className={`text-white mb-0 text-body`}>
                    M:{" "}
                    <a href={`mailto:info@safia.tech`}>
                      <span>info@safia.tech</span>
                    </a>
                  </div>
                </span>
              </div>
              <div className="mt-8">
                <SocialMediaIcons />
              </div>
            </div>
          </div>
        </div>
        {/* <NewsletterSubscribe /> */}
      </div>
    </Layout>
  );
};

export default ContactPage;
